// Generated by Framer (7f988a2)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/YclOoM8LK";

const cycleOrder = ["SC7Ow25jK"];

const serializationHash = "framer-bY69M"

const variantClassNames = {SC7Ow25jK: "framer-v-17p0kaq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({background, height, id, title, width, ...props}) => { return {...props, cHNwfSZXG: background ?? props.cHNwfSZXG ?? "var(--token-c19f9397-63ce-4c09-9404-3632ec0d3f10, rgb(247, 175, 231))", e_zKD7Y57: title ?? props.e_zKD7Y57 ?? "slow production"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;background?: string;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, cHNwfSZXG, e_zKD7Y57, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "SC7Ow25jK", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-17p0kaq", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"SC7Ow25jK"} ref={ref ?? ref1} style={{backgroundColor: cHNwfSZXG, borderBottomLeftRadius: 30, borderBottomRightRadius: 30, borderTopLeftRadius: 30, borderTopRightRadius: 30, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1crqnco"} data-styles-preset={"YclOoM8LK"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-4afff1e1-ad66-4449-a126-34071669c871, rgb(17, 17, 17)))"}}>slow production</motion.p></React.Fragment>} className={"framer-1xo5qao"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"mQibdK3P9"} style={{"--extracted-r6o4lv": "var(--token-4afff1e1-ad66-4449-a126-34071669c871, rgb(17, 17, 17))"}} text={e_zKD7Y57} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bY69M.framer-3cn7b7, .framer-bY69M .framer-3cn7b7 { display: block; }", ".framer-bY69M.framer-17p0kaq { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: auto; justify-content: center; overflow: hidden; padding: 20px; position: relative; width: auto; will-change: var(--framer-will-change-override, transform); }", ".framer-bY69M .framer-1xo5qao { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-bY69M.framer-17p0kaq { gap: 0px; } .framer-bY69M.framer-17p0kaq > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-bY69M.framer-17p0kaq > :first-child { margin-left: 0px; } .framer-bY69M.framer-17p0kaq > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 191
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"cHNwfSZXG":"background","e_zKD7Y57":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerIVqip1dOo: React.ComponentType<Props> = withCSS(Component, css, "framer-bY69M") as typeof Component;
export default FramerIVqip1dOo;

FramerIVqip1dOo.displayName = "Large tag";

FramerIVqip1dOo.defaultProps = {height: 64, width: 191};

addPropertyControls(FramerIVqip1dOo, {cHNwfSZXG: {defaultValue: "var(--token-c19f9397-63ce-4c09-9404-3632ec0d3f10, rgb(247, 175, 231)) /* {\"name\":\"web.pink.400(base)\"} */", title: "Background", type: ControlType.Color}, e_zKD7Y57: {defaultValue: "slow production", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerIVqip1dOo, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})